import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import Retranscriptions from './Components/Retranscriptions/Retranscriptions';
import SideMenu from './Components/SideMenu/SideMenu';
import StatsTable from './Components/StatsTable/StatsTable';
import BookletsPage from './Components/BookletsPage/BookletsPage'
import ThanksPage from './Components/ThanksPage/ThanksPage';
import Suivis from './Components/Suivis/Suivis'
import Archives from './Components/Archives/Archives'
import AdminAuthent from './Components/AdminAuthent/AdminAuthent'

const menu = {Home:0, Stats:1, BookletsPage:2, Suivis:3, Archives:4, Disconnect:5, ThanksPage:6};
class App extends Component {
  

  constructor(props){
    super(props);
    this.debug = true;

    if (this.debug){
      this.state = {menuState:menu.Home, authentified:1}/*Debugged page*/
    }
    else{
      this.state = {menuState:menu.Stats, authentified:0}/*Default page*/
    }
  }

  onMenuChange = selectedMenu => {
    console.log("onMenuChange->", selectedMenu)
    this.setState({menuState:menu[selectedMenu]})
  }

  onDisconnect = () =>{
    this.setState({authentified:0})
  }
  
  render = () =>{
    return (
      <div className="App">
        {this.state.authentified == 2 && 
        <div>
        <h1 style= {{color:"blue"}}>Déploiement en cours...</h1>
        <h3>L'application sera à nouveau disponible d'ici midi aujourd'hui 10 septembre 2021</h3></div>}

        {this.state.authentified == 0 && 
        <AdminAuthent onOperatorGranted={()=>{this.setState({authentified:1})}}/>}

        {this.state.authentified == 1 &&
        <div>
          <SideMenu onMenuChange = {this.onMenuChange} onDisconnect={this.onDisconnect}/>
          <div>
            {(this.state.menuState == menu.Home) && 
              <div>
                {/*<h1 className="App-header-disabled">Jésus est la réponse pour tous</h1>*/}
                <h4>Console de retranscription des requêtes</h4>
                <Retranscriptions mode="remote"/>
              </div>
            }

            {(this.state.menuState == menu.Stats) && 
              <div>
                <h4>Tableau de bord des requêtes</h4>
                <StatsTable/>
              </div>
            }
            
            {(this.state.menuState == menu.BookletsPage) && 
              <div>
                <h4>Livrets</h4>
                <BookletsPage/>
              </div>
            }
            
            {(this.state.menuState == menu.Suivis) && 
              <div>
                <h4>Campagnes de Suivis de requêtes</h4>
                <Suivis/>
              </div>
            }

            {(this.state.menuState == menu.ThanksPage) && 
              <div>
                <h4>Remerciements</h4>
                <ThanksPage/>
              </div>
            }
            
            {(this.state.menuState == menu.Archives) && 
              <div>
                <h4>Requêtes archivées</h4>
                <Archives/>
              </div>
            }
          </div>
        </div>}
      </div>
    );
  }
}

export default App;
