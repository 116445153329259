import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {FormErrors} from '../FormErrors/FormErrors';
import axios from 'axios';
import SaveAlt from '@material-ui/icons/SaveAlt';

class ModalAffectationLivret extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booklet_data:[],
      date:"",
      formErrors: {date: ''},
      assigned:false
    };
  }

  componentDidMount = () => {
    this.service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4002/assign-request-to-booklet'
                            :`https://envois.jesusestlareponsepourtous.org/assign-request-to-booklet`;
  }

  //---Form methods
  clearForm = () => {
    //Reset state
    this.setState({booklet_data:[], date:"", ormErrors: {date: ''},assigned:false, did_not_change:true});
  }

  onPublicationFormSubmitHandler = async event => {
    event.preventDefault();

    var selectedBookletDate = new Date(this.state.date);
    const month_name = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"];
    
    //Build request id's list 
    console.log("onPublicationFormSubmitHandler => data", this.props.data)
    const idrequetes_list = Object.values(this.props.data).map(
      requete => {
          return (
              `${requete.idrequetes}`
          )
      }
    );

    try{
        var insert_envoi_parameters = {
                                        booklet: `Livret du ${this.state.date}`,
                                        month:1 + selectedBookletDate.getMonth(), 
                                        day: selectedBookletDate.getDate(),
                                        year: selectedBookletDate.getFullYear(),
                                        idrequetes_list : idrequetes_list.join(",")
                                      };
        
                    
        //call requete-service
        const serviceResult = await axios.put(
          this.service_db_url,
            insert_envoi_parameters);

        //Get service aknowledge before resetting state values.
        if (serviceResult.data.state === true){
            console.log("requête envoyée : result.data.message => ", serviceResult.data.message);
            
            //Display back full requetes paragraph in text area
            this.setState({assigned:true}, ()=>{console.log("state after assignation", this.state);});

            //Refresh master table
            //2DO DEBUG - It closes lovely modal window
            //this.props.refreshHandler();
        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
      alert("Vérifiez votre connexion Wifi : " + err);
    }
  }

  handle_date = (e) => {
    //Update date value from form field
    this.setState({did_not_change:false, date:e.target.value}, () => {this.validateField(e.target.name, e.target.value);});
    console.log("date =>", e.target.value);
  }

  errorClass = (error) => {
    return(error.length === 0 ? '' : 'has-error');
  }

  validateForm = () => {
    this.setState({formValid: this.state.dateValid});
  }

  validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let dateValid = this.state.dateValid;
    
      switch(fieldName) {
        case 'date':
          //dateValid = value.match(/^(0|(\\+33)|(0033))[1-9][0-9]{8}$/i);
          dateValid = (value != "");
          fieldValidationErrors.date = dateValid ? '' : ' est invalide';
          break;

        default:
          break;
  }

  this.setState({formErrors: fieldValidationErrors,
                  dateValid: dateValid
                }, this.validateForm);
  }

  formDisabled = () => {
      return (
          !(this.state.date.length > 0
          && this.state.formErrors.date.length == 0
          ));
  }

  render() {
    {/*const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.toggle}>&times;</button>;*/}
    return (
      <div>
        {/*<SaveAlt onClick={() => {alert("Toggle twice !"); this.props.toggle();}}/>*/}
        <SaveAlt/>
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>{/* External props.toggle in order to support material table global action button */}
          <ModalHeader>Affectation au livret</ModalHeader>
          
          <ModalBody>
                <q>
                  Cette opération vise à affecter la ou les requetes sélectionnées à un livret !
                </q>
                <div className="panel panel-default">
                    <FormErrors formErrors={this.state.formErrors}/>
                </div>
                <form id="publication-form" onSubmit={this.onPublicationFormSubmitHandler}> 
                <div className={`form-group
                 ${this.errorClass(this.state.formErrors.date)}`}/>                   
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Date du livret:</label>
                        <div className="col-sm-10">
                          <input 
                              name="date" data-inputmask="'alias': 'date'" 
                              className="form-control" value={this.state.date} 
                              onChange={(e)=>{this.handle_date(e);}} type="date" 
                              placeholder="Votre date du livret"/>
                        </div>
                    </div>
                </form>

                {this.state.assigned && <div>Requête affectée au livret</div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" form="publication-form" hidden={this.state.assigned} disabled={this.formDisabled()}>Affecter au livret</Button>
            <Button color="secondary" onClick={() => {this.props.toggle(); this.clearForm();}} >Fermer</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalAffectationLivret;